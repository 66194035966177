(function() {
    'use strict';

    angular
        .module('app')
        .directive('freeSearch', freeSearch);

    freeSearch.$inject = [];
    /* @ngInject */
    function freeSearch() {
        var directive = {
            bindToController: true,
            controller: FreeSearchController,
            controllerAs: 'vm',
            restrict: 'A',
            templateUrl: 'layout/free-search/free-search.view.html',
            replace: true,
            scope: {
                config: '=?'
            }
        };

        return directive;
    }

    FreeSearchController.$inject = ['$scope', '$rootScope', '$element', '$timeout', '$state', 'loadGoogleMaps', 'categoriesService'];
    /* @ngInject */
    function FreeSearchController($scope, $rootScope, $element, $timeout, $state, loadGoogleMaps, categoriesService) {
        var vm = this;

        var infSelectConfig = {
            tree: null,
            propertyToShow: 'ukName',
            preselected: vm.config.categoryId || null,
            selectedList: [],
            onSelectChange: onCategorySelectChange
        };

        vm.radiusOptions = [
            {
                value: 1,
                name: '1 км',
                label: 'Шукати у радіусі одного кілометра'
            },
            {
                value: 2,
                name: '2 км',
                label: 'Шукати у радіусі двох кілометрів'
            },
            {
                value: 3,
                name: '3 км',
                label: 'Шукати у радіусі трьох кілометрів'
            }
        ];

        vm.setRadius = setRadius;
        vm.isRadiusOptionActive = isRadiusOptionActive;
        vm.goToSearchState = goToSearchState;

        ///////////////

        activate();

        ///////////////

        function activate() {
            getCategories();
            loadGoogleMaps.mapsInitialized.then(function() {
                $timeout(function() {
                    attachPlacesInput();
                    parseConfig();
                });
            });
        }

        function getCategories() {
            return categoriesService.getTree().then(function(response) {
                infSelectConfig.tree = response.data;
                vm.infSelectConfig = infSelectConfig;
            });
        }

        function attachPlacesInput() {
            var input = $element.find('#free-search-place-input')[0];
            var autocomplete = new google.maps.places.Autocomplete(input);

            google.maps.event.addListener(autocomplete, 'place_changed', function() {
                var place = autocomplete.getPlace();
                var position = getPosition(place);

                vm.position = {
                    lat: position.lat,
                    lng: position.lng,
                    address: input.value
                };

                if (!vm.config.submitButton) {
                    goToSearchState();
                }
            });
        }

        function parseConfig() {
            if (!_.isObject(vm.config)) { return false; }
        }

        function getPosition(place) {
            if (!place || !place.geometry) { return false; }

            return {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng()
            };
        }

        function setRadius(value) {
            vm.config.radius = value;

            if (!vm.config.submitButton) {
                goToSearchState();
            }
        }

        function isRadiusOptionActive(value) {
            return vm.config.radius === value;
        }

        function onCategorySelectChange() {
            if (!vm.infSelectConfig.selectedList.length) { return false }

            var lastSelected = _.last(vm.infSelectConfig.selectedList);

            vm.lastSelectedCat = lastSelected ? lastSelected.id : null;

            if (!vm.config.submitButton) {
                goToSearchState();
            }
        }

        function goToSearchState() {
            if (!vm.position) {
                vm.showLocationError = true;
                return;
            }

            $rootScope.isSearchPanelExpanded = false;
            $state.go('search.results-state', {
                lat: vm.position.lat,
                lng: vm.position.lng,
                address: vm.position.address,
                categoryId: vm.lastSelectedCat,
                radius: vm.config.radius
            }, {
                reload: false,
                notify: false,
                inherit: true
            });
        }
    }
})();