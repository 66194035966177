(function () {
    'use strict';

    angular
        .module('app')
        .controller('BaseController', BaseController);

    BaseController.$inject = ['$state', 'users', '$rootScope', 'metaTags'];
    /* @ngInject */
    function BaseController($state, users, $rootScope, metaTags) {
        var vm = this;

        vm.users = users;
        vm.states = [];
        vm.metaTags = metaTags;

        vm.isActiveStateWide = isActiveStateWide;
        vm.closeSidePanels = closeSidePanels;
        //vm.isActiveStateFullHeight = isActiveStateFullHeight;

        activate();

        function activate() {
            $rootScope.$on('token-invalid', function(){
                users.logout();
            });
        }

        function closeSidePanels() {
            closeLeftPanel();
            closeSearchPanel();
        }

        function closeLeftPanel() {
            $rootScope.isSideMenuExpanded = false;
        }

        function closeSearchPanel() {
            $rootScope.isSearchPanelExpanded = false;
        }

        function isActiveStateWide() {
            var wideStates = [
                'home',
                'about',
                'login',
                'login.signin',
                'login.signup',
                'confirm-email',
                'forgot-password',
                'restore-password',
                'services'
            ];

            return _.indexOf(wideStates, $state.current.name) !== -1;
        }

    }
})();

