(function() {
    'use strict';

    angular
        .module('app')
        .factory('GlobalSearch', globalSearch);

    globalSearch.$inject = ['$http', 'global', 'InstitutionsList'];
    /* @ngInject */
    function globalSearch($http, global, InstitutionsList) {

        var apiUrl = global.apiUrl;

        function GlobalSearch(options) {
            this.query = '';
            this.searchInstitutions = false;
            this.searchCategories = true;
            this.categoriesLimit = 3;
            this.institutionsLimit = 7;
            this.institutions = [];
            this.categories = [];
            this.isLoadInProcess = false;
            this.init(options);
        }

        GlobalSearch.prototype.init = function(options) {
            if (options.query) { this.query = options.query; }
            if (options.searchInstitutions) { this.searchInstitutions = options.searchInstitutions; }
            if (options.searchCategories) { this.searchCategories = options.searchCategories; }
            if (options.categoriesLimit) { this.categoriesLimit = options.categoriesLimit; }
            if (options.institutionsLimit) { this.institutionsLimit = options.institutionsLimit; }
        };

        GlobalSearch.prototype.onQueryChanged = function(query) {
            this.query = query;
            this.reset();

            if (this.query.length > 2) {
                this.getRemote();
            }
        };

        GlobalSearch.prototype.reset = function() {
            this.institutions.length = 0;
            this.categories.length = 0;
        };

        GlobalSearch.prototype.getRemote = function() {
            this.isLoadInProcess = true;
            return $http.get(apiUrl +  '/search', { params: this.getSearchParams() })
                .catch(this.onError.bind(this))
                .then(this.onSuccess.bind(this))
                .finally(this.onFinally.bind(this))
        };

        GlobalSearch.prototype.onSuccess = function(response) {
            if (!response) { return false; }

            if (response.data && response.data.institutions) {
                var institutions = new InstitutionsList({
                    data: response.data.institutions
                });
                this.institutions = institutions.getList();
            }

            if (response.data && response.data.categories) {
                this.categories = response.data.categories;
            }
        };

        GlobalSearch.prototype.onError = function() {

        };

        GlobalSearch.prototype.onFinally = function() {
          this.isLoadInProcess = false;
        };

        GlobalSearch.prototype.getSearchParams = function() {
            var params = {};

            if (this.searchCategories) {
                params.searchCategories = true;
            }

            if (this.searchInstitutions) {
                params.searchInstitutions = true;
            }

            if (this.categoriesLimit) {
                params.categoriesLimit = this.categoriesLimit;
            }

            if (this.institutionsLimit) {
                params.institutionsLimit = this.institutionsLimit;
            }

            if (this.query) {
                params.query = this.query;
            }

            return params;
        };

        return GlobalSearch;
    }
})();