(function() {
    'use strict';

    angular
        .module('app')
        .factory('User', user);

    user.$inject = [];
    /* @ngInject */
    function user() {


        function User(options) {
            this.init(options);
        }

        User.prototype.init = function(options) {
            this.id = options && options.id ? options.id : null;
            this.email = options && options.email ? options.email : null;
            this.firstName = options && options.firstName ? options.firstName : null;
            this.lastName = options && options.lastName ? options.lastName : null;
            this.photoId = options && options.photoId ? options.photoId : null;
            this.smallPhotoUrl = options && options.smallPhotoUrl ? options.smallPhotoUrl : null;
            this.eventHandlers = options && options.eventHandlers ? options.eventHandlers : null;
            this.role = options && options.role ? options.role : null;
            if (options.createdAt) { this.createdAt = options.createdAt; }
            this.institutionsCount = options.institutionsCount || 0;
        };

        return User;
    }
})();