(function () {
    'use strict';

    angular
        .module('app')
        .directive('paginatedList', paginatedList);

    paginatedList.$inject = [];
    /* @ngInject */
    function paginatedList() {
        var directive = {
            replace: true,
            bindToController: true,
            controller: PaginatedListController,
            controllerAs: 'vm',
            restrict: 'EA',
            templateUrl: 'layout/paginated-list/paginated-list.html',
            transclude: true,
            scope: {
                options: '='
            }
        };

        return directive;
    }

    PaginatedListController.$inject = [];

    function PaginatedListController() {
        var vm = this;


        activate();

        function activate() {

        }
    }

})();