(function () {
    'use strict';

    angular
        .module('app')
        .directive('dashboardListItem', dashboardListItem);

    dashboardListItem.$inject = [];
    /* @ngInject */
    function dashboardListItem() {
        var directive = {
            replace: true,
            bindToController: true,
            controller: DashboardListItemController,
            controllerAs: 'vm',
            restrict: 'EA',
            templateUrl: 'layout/dashboard-list-item/dashboard-list-item.html',
            scope: {
                item: '=',
                options: '='
            }
        };

        return directive;
    }

    DashboardListItemController.$inject = ['$state'];

    function DashboardListItemController($state) {
        var vm = this;

        vm.indicators = [];
        vm.link = null;
        vm.title = vm.item[vm.options.title];

        activate();

        function activate() {
            setIndicators();
            setLink()
        }

        function setIndicators() {
            _.each(vm.options.indicators, function(elem) {
                var indicator = {};

                indicator.className = elem.className;

                if (elem.isUTCTimestamp && vm.item[elem.field]) {
                    indicator.title = formatLocalTime(vm.item[elem.field]);
                    indicator.value = formatTimeAgo(vm.item[elem.field]);
                    indicator.isUTCTimestamp = true;
                } else {
                    indicator.title = elem.title;
                    indicator.value = vm.item[elem.field];
                }

                vm.indicators.push(indicator);
            });
        }

        function setLink() {
            var params = {};
            _.each(vm.options.stateParamsMap, function(value, key) {
                params[key] = vm.item[value];
            });

            vm.link = $state.href(vm.options.state, params);
        }

        function utcToLocalTime(timeObject) {
            var dateTime = timeObject.date;

            var stillUtc = moment.utc(dateTime).toDate();
            return moment(stillUtc).local();
        }

        function formatTimeAgo(timeObject) {
            return utcToLocalTime(timeObject).fromNow();
        }

        function formatLocalTime(timeObject) {
            return utcToLocalTime(timeObject).format('YYYY-MM-DD HH:mm:ss');
        }
    }

})();