(function () {
    'use strict';
    angular
        .module('app')
        .controller('ProfileController', ProfileController);

    ProfileController.$inject = ['$stateParams', 'users', 'photosService', 'InstitutionsList'];

    function ProfileController($stateParams, users, photosService, InstitutionsList) {
        var vm = this;
        var currentUserId = users.current && users.current.id;

        vm.isImageEditable = true;
        vm.selImgConfig = {
            aspectRatio: 1,
            resizeTo: 700,
            mWidth: 500,
            onChange: onProfileImageChange
        };
        vm.logOut = logOut;

        activate();

        function activate() {
            getUser(currentUserId);
            vm.institutions = new InstitutionsList({ownerId: currentUserId});
            vm.institutions.getRemote();
        }

        function logOut() {
            users.logout();
        }

        function getUser(id) {
            users.getUser(id).then(function(response) {
                vm.user = response.data;
                getProfilePhoto(vm.user.photoId);
            });
        }

        function onProfileImageChange(image) {
            photosService.add(image).then(function(response) {
                users.update(users.current.id, {photoId: response.data.id});
            });
        }

        function getProfilePhoto(photoId) {
            if (!photoId) {
                vm.image = {
                    src: vm.user.smallPhotoUrl
                };

                return false;
            }
            photosService.get(photoId).then(function(response) {
                vm.image = response.data;
            });
        }
    }

})();

