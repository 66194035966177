(function() {
    'use strict';

    angular
        .module('app')
        .factory('Institution', institution);

    institution.$inject = ['$state', 'instService'];
    /* @ngInject */
    function institution($state, instService) {
        _(Institution.prototype).extend(EventEmitter.prototype);

        function Institution(params) {
            this.id = null;
            this.title = '';
            this.categoryId = null;
            this.description = '';
            this.photoId = null;
            this.imgUrl = null;
            this.lat = '';
            this.lng = null;
            this.address = '';
            this.owner = null;

            this.init(params);
        }

        Institution.prototype.init = function(params) {
            if (params.id) { this.id = params.id; }
            if (params.title) { this.title = params.title; }
            if (params.categoryId) { this.categoryId = params.categoryId; }
            if (params.description) { this.description = params.description; }
            if (params.photoId) { this.photoId = params.photoId; }
            if (params.imgUrl) { this.imgUrl = params.imgUrl; }
            if (params.lat) { this.lat = params.lat; }
            if (params.lng) { this.lng = params.lng; }
            if (params.address) { this.address = params.address; }
            if (params.owner) { this.owner = params.owner; }
            if (params.isHidden) { this.isHidden = params.isHidden; }
            if (params.phoneNumbers) { this.phoneNumbers = params.phoneNumbers; }
            if (params.recruitFrom) { this.recruitFrom = params.recruitFrom; }
            if (params.recruitTo) { this.recruitTo = params.recruitTo; }
            if (params.createdAt) { this.createdAt = params.createdAt; }

            if (params.photosCount || params.photosCount === 0) { this.photosCount = params.photosCount; }
            if (params.schedulesCount || params.schedulesCount === 0) { this.schedulesCount = params.schedulesCount; }
            if (params.phoneNumbersCount || params.phoneNumbersCount === 0) { this.phoneNumbersCount = params.phoneNumbersCount; }
        };

        Institution.prototype.setHidden = function(value) {
            var _this = this;
            return instService.hide(this.id, value)
                .catch(function(err) {
                    console.warn(err);
                })
                .then(function () {
                    _this.isHidden = value;
                });
        };

        Institution.prototype.getRemote = function() {
            var _this = this;

            return instService.get(this.id)
                .catch(function(error) {
                    if (error && error.data && error.data.code === 404) {
                        $state.go('404');
                    }
                })
                .then(function(response) {
                    if (!response) { return false; }
                    _this.init(response.data);
                });
        };

        Institution.prototype.updatePhoneNumber = function(options) {
            _.find(this.phoneNumbers, function(elem) {
               if (elem.id === options.id) {
                   elem.rawNumber = options.rawNumber;
               }
            });
        };


        return Institution;
    }
})();