(function () {
    'use strict';

    angular
        .module('app')
        .directive('sideMenu', sideMenu);

    sideMenu.$inject = [];
    /* @ngInject */
    function sideMenu() {
        var directive = {
            replace: true,
            bindToController: true,
            controller: SideMenuController,
            controllerAs: 'vm',
            restrict: 'A',
            templateUrl: 'layout/side-menu/side-menu.html',
            scope: {}
        };

        return directive;
    }

    SideMenuController.$inject = [];

    function SideMenuController() {
        var vm = this;

        vm.catNavigationConfig = {
            updateRoute: false
        };


        activate();

        function activate() {

        }
    }
})();