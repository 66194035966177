(function () {
    'use strict';

    angular
        .module('app')
        .controller('PageNotFoundController', pageNotFoundController);

    pageNotFoundController.$inject = [];

    function pageNotFoundController() {
        var vm = this;

    }

})();