(function() {
    'use strict';

    angular
        .module('app')
        .factory('UsersListPaginated', usersListPaginated);

    usersListPaginated.$inject = ['DataModelListPaginated', 'users', 'User'];
    /* @ngInject */
    function usersListPaginated(DataModelListPaginated, users, User) {
        _(UsersListPaginated.prototype).extend(DataModelListPaginated.prototype);

        function UsersListPaginated(options) {
            this.model = options.model;
            this.offset = options.offset || 0;
            this.limit = options.limit || 10;
            this.pageNumber = options.pageNumber || 0;
            this.data = [];
            this.model = User;
            this.isLoading = false;
        }

        UsersListPaginated.prototype.getRemote = function() {
            this.isLoading = true;

            return users.getAll({
                offset: this.offset,
                limit: this.limit
            }).then(this.onRemoteRequestSuccess.bind(this));
        };



        return UsersListPaginated;
    }
})();