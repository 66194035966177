(function () {
    'use strict';

    angular
        .module('app')
        .directive('pagination', pagination);

    pagination.$inject = [];
    /* @ngInject */
    function pagination() {
        var directive = {
            replace: true,
            bindToController: true,
            controller: PaginationController,
            controllerAs: 'vm',
            restrict: 'EA',
            templateUrl: 'layout/pagination/pagination.html',
            scope: {
                options: '='
            }
        };

        return directive;
    }

    PaginationController.$inject = [];

    function PaginationController() {
        var vm = this;

        vm.previousPage = previousPage;
        vm.nextPage = nextPage;

        function previousPage() {
            vm.options.previousPage();
        }

        function nextPage() {
            vm.options.nextPage();
        }
    }

})();