(function () {
    'use strict';

    angular
        .module('app')
        .directive('searchMenu', searchMenu);

    searchMenu.$inject = [];
    /* @ngInject */
    function searchMenu() {
        var directive = {
            replace: true,
            bindToController: true,
            controller: SearchMenuController,
            controllerAs: 'vm',
            restrict: 'A',
            templateUrl: 'layout/search-menu/search-menu.html',
            scope: {}
        };

        return directive;
    }

    SearchMenuController.$inject = ['$stateParams'];

    function SearchMenuController($stateParams) {
        var vm = this;

        vm.nameMode = false;
        vm.addressMode = true;

        vm.catNavigationConfig = {
            updateRoute: false
        };

        vm.searchConfig = {
            address: $stateParams.address || null,
            radius: parseInt($stateParams.radius) || 1,
            categoryId: $stateParams.categoryId,
            submitButton: true
        };

        vm.switchToAddressMode = switchToAddressMode;
        vm.switchToNameMode = switchToNameMode;



        activate();

        function activate() {

        }

        function switchToAddressMode() {
            vm.addressMode = true;
            vm.nameMode = false;
        }

        function switchToNameMode() {
            vm.nameMode = true;
            vm.addressMode = false;
        }
    }
})();