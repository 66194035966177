(function () {
    'use strict';

    angular
        .module('app')
        .directive('nameSearch', nameSearch);

    nameSearch.$inject = [];
    /* @ngInject */
    function nameSearch() {
        var directive = {
            replace: true,
            bindToController: true,
            controller: NameSearchController,
            controllerAs: 'vm',
            restrict: 'A',
            templateUrl: 'layout/name-search/name-search.html',
            scope: {}
        };

        return directive;
    }

    NameSearchController.$inject = ['$stateParams', 'GlobalSearch'];

    function NameSearchController($stateParams, GlobalSearch) {
        var vm = this;

        vm.query = '';
        vm.searchConfig = {
            address: $stateParams.address || null,
            radius: parseInt($stateParams.radius) || 1,
            categoryId: $stateParams.categoryId
        };

        vm.onQueryChanged = _.debounce(onQueryChanged, 150);
        vm.isNoResultsMessageVisible = isNoResultsMessageVisible;

            activate();

        function activate() {
            vm.search = new GlobalSearch({
                categoriesLimit: 3,
                institutionsLimit: 10,
                searchInstitutions: true,
                searchCategories: true
            })
        }

        function onQueryChanged() {
            vm.search.onQueryChanged(vm.query);
        }

        function isNoResultsMessageVisible() {
            return vm.search &&
                vm.search.query &&
                vm.search.query.length > 2 &&
                !vm.search.isLoadInProcess &&
                !vm.search.institutions.length &&
                !vm.search.categories.length;
        }

    }
})();