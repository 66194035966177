(function () {
    'use strict';
    angular
        .module('app')
        .directive('photos', photos);

    photos.$inject = [];
    /* @ngInject */
    function photos() {
        var directive = {
            bindToController: true,
            controller: PhotosController,
            controllerAs: 'vm',
            restrict: 'A',
            templateUrl: 'institutions-state/institution-state/directives/photos/photos.html',
            scope: {
                institution: '='
            }
        };

        return directive;
    }

    PhotosController.$inject = ['$scope', '$rootScope', '$stateParams', 'users', 'MediaItemsList',
        'instService', 'photoswipe'];

    function PhotosController($scope, $rootScope, $stateParams, users, MediaItemsList,
                                         instService, photoswipe) {
        var vm = this;

        var instId = $stateParams.id;
        var listeners = [];

        vm.photos = new MediaItemsList({
            type: 'photos',
            institutionId: instId
        });

        vm.addImageConfig = {
            instId: instId,
            rawDownload: true,
            aspectRatio: 1,
            resizeTo: 640,
            instance: 'institution',
            instanceId: instId,
            onAdded: onAdded
        };

        vm.isOwnerOrAdmin = isOwnerOrAdmin;
        vm.initPhotoSwipe = initPhotoSwipe;
        vm.loadMore = loadMore;
        vm.isMoreButtonVisible = isMoreButtonVisible;

        activate();

        function activate() {
            vm.inst = vm.institution;
            vm.photos.getRemote();

            listeners.push($rootScope.$on('photo-added', function(e, photo) {
                vm.photos.add(photo, true);
            }));

            listeners.push($rootScope.$on('photo-deleted', function(event, payload) {
                vm.photos.removeById(payload.id);
            }));

            $scope.$on('$destroy', function() {
                _.each(listeners, function(elem) {
                    elem();
                })
            });
        }

        function isOwnerOrAdmin() {
            if (!vm.inst || !users.current) { return false; }
            var isOwner = parseInt(vm.inst.owner) === parseInt(users.current.id);
            var isAdmin = users.current.role === 'admin' || users.current.role === 'superman';

            return isAdmin || isOwner;
        }

        function initPhotoSwipe(index) {
            photoswipe.init(angular.copy(vm.photos.data), index);
        }

        function loadMore() {
            if (vm.photos.isLoadInProcess || vm.photos.areAllItemsLoaded()) { return false; }

            vm.photos.getRemote();
        }

        function onAdded(photo) {
            vm.photos.add(photo);
        }

        function isMoreButtonVisible() {
            return !vm.photos.areAllItemsLoaded() && !vm.photos.isLoadInProcess;
        }
    }

})();

