(function() {
    'use strict';

    angular
        .module('app')
        .factory('global', global);

    global.$inject = ['$location'];

    function global($location) {
    	var service = {
            apiUrl: null,
            states: null
    	};

    	init();

        function init() {
            switch($location.host()) {
                case 'localhost':
                    service.apiUrl = 'http://127.0.0.1:8000';
                    break;
                case 'stage.dreamtame.com':
                    service.apiUrl = 'https://stage-api.dreamtame.com';
                    break;
                case 'dreamtame.com':
                    service.apiUrl = 'https://api.dreamtame.com';
                    break;
                default:
                    service.apiUrl = 'https://stage-api.dreamtame.com';
                    break;
            }
        }

    	return service;
    }

})();
