(function() {
    'use strict';

    angular
        .module('app')
        .factory('DataModelList', dataModelList);

    dataModelList.$inject = [];
    /* @ngInject */
    function dataModelList() {
        // _(DataModelList.prototype).extend(EventEmitter.prototype);

        function DataModelList(options) {
            this.init(options);

            this.model = options.model;
            this.data = [];
            this.isLoading = false;
        }

        DataModelList.prototype.getRemote = function() {
          //To be overwritten in ancestors
        };

        DataModelList.prototype.onRemoteRequestSuccess = function(response) {
            this.addList(response.data.data);
            this.isLoading = false;
        };

        DataModelList.prototype.add = function(modelData) {
            this.data.push(new this.model(modelData))
        };

        DataModelList.prototype.addList = function(modelDataList) {
            _.each(modelDataList, function(elem) {
                this.add(elem);
            }, this)
        };

        return DataModelList;
    }
})();