(function () {
    'use strict';
    angular
        .module('app')
        .directive('instComments', instComments);

    instComments.$inject = [];
    /* @ngInject */
    function instComments() {
        var directive = {
            bindToController: true,
            controller: InstCommentsController,
            controllerAs: 'vm',
            restrict: 'A',
            templateUrl: 'institutions-state/institution-state/directives/comments/comments.html',
            scope: {
                institution: '='
            }
        };

        return directive;
    }

    InstCommentsController.$inject = [];

    function InstCommentsController() {
        var vm = this;

        activate();

        function activate() {
            vm.options = { institution: vm.institution };
        }
    }

})();

