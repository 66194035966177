(function () {
    'use strict';
    angular
        .module('app')
        .controller('InstitutionController', InstitutionController);

    InstitutionController.$inject = ['$rootScope', '$scope', '$state', '$stateParams', 'users', 'photosService', 'metaTags',
                                    'instService', 'categoriesService', 'photoswipe', 'modalService', 'Institution'];

    function InstitutionController($rootScope, $scope, $state, $stateParams, users, photosService, metaTags,
                                   instService, categoriesService, photoswipe, modalService, Institution) {
        var vm = this;

        var instId = $stateParams.id;
        vm.instId = instId;

        vm.selImgConfig = {
            aspectRatio: 1,
            resizeTo: 700,
            mWidth: 500,
            instance: 'institution',
            instanceId: instId,
            onChange: onInstImageChange
        };

        vm.isStoryDescriptionTruncated = true;
        vm.categoriesService = categoriesService;

        vm.isOwnerOrAdmin = isOwnerOrAdmin;
        vm.initPhotoSwipe = initPhotoSwipe;
        vm.isSubStateActive = isSubStateActive;
        vm.isAdmin = isAdmin;
        vm.openSideMenu = openSideMenu;

        activate();

        function activate() {
            if ($state.current.name !== 'institutions.institution') {
                $state.go('institutions.institution', {id: instId});
            }

            getInst();

            $scope.$on('$destroy', function() {
                metaTags.reset();
            });
        }

        function getInst() {
            vm.inst = new Institution({id: instId});
            vm.inst.getRemote().then(onInstitutionLoaded.bind(this));
        }

        function onInstitutionLoaded() {
            vm.isImageEditable = parseInt(vm.inst.owner) === parseInt(users.current.id);
            categoriesService.activeId = vm.inst.categoryId;

            metaTags.setTitle(getPageTitle(vm.inst.title));
            metaTags.setDescription(vm.inst.description);

            setMapConfig();
            getInstPhoto(vm.inst.photoId);
            emitActiveCatChangeEvent(vm.inst.categoryId);
        }

        function getPageTitle(instTitle) {
            return instTitle + ' - Dreamtame';
        }

        function setMapConfig() {
            vm.mapConfig = {
                id: 'inst-map-' + chance.guid(),
                lat: vm.inst.lat,
                lng: vm.inst.lng,
                address: vm.inst.address,
                title: vm.inst.title
            }
        }

        function emitActiveCatChangeEvent(catId) {
            $rootScope.$emit('activeCatChange', catId);
        }

        function onInstImageChange(image) {
            photosService.add(image).then(function(response) {
                instService.update(vm.inst.id, {photoId: response.data.id});
            });
        }

        function getInstPhoto(photoId) {
            if (!photoId) { return false; }
            photosService.get(photoId).then(function(response) {
                vm.image = response.data;
            });
        }

        function isOwnerOrAdmin() {
            if (!vm.inst || !users.current) { return false; }
            var isOwner = parseInt(vm.inst.owner) === parseInt(users.current.id);
            var isAdmin = users.current.role === 'admin' || users.current.role === 'superman';

            return isAdmin || isOwner;
        }

        function initPhotoSwipe(items, index) {
            var trueIndex = getPhotoTrueIndex(index);
            photoswipe.init(angular.copy(items), trueIndex);
        }

        function isSubStateActive(name) {
            if (!$state.current || !$state.current.name) { return false; }
            return $state.current.name.includes(name);
        }

        function isAdmin() {
            if (!users.current) { return false; }

            return  users.current.role === 'admin' || users.current.role === 'superman';
        }

        function openSideMenu() {
            $rootScope.isSideMenuExpanded = true;
        }
    }

})();

