(function () {
    'use strict';
    angular
        .module('app')
        .controller('DashboardInstitutionsController', DashboardInstitutionsController);

    DashboardInstitutionsController.$inject = ['InstitutionsListPaginated'];

    function DashboardInstitutionsController(InstitutionsListPaginated) {
        var vm = this;

        vm.paginatedListOptions = null;

        vm.dashboardListItemConfig = {
            title: 'title',
            state: 'institutions.institution.photos',
            stateParamsMap: {id: 'id'},
            indicators: [
                {className: 'fa-list-ol', field: 'schedulesCount', title: 'Розклад Роботи'},
                {className: 'fa-file-image-o', field: 'photosCount', title: 'Кількість Фото'},
                {className: 'fa-user', field: 'owner', title: 'ID користувача'},
                {className: 'fa-phone', field: 'phoneNumbersCount', title: 'Кількість Телефонних Номерів'},
                {className: 'fa-calendar', field: 'createdAt', title: 'Дата Додавання', isUTCTimestamp: true}
            ]
        };

        activate();

        function activate() {
            vm.institutionsList = new InstitutionsListPaginated({
                limit: 10,
                offset: 0
            });

            vm.institutionsList.getRemote()
                .then(function() {
                    vm.paginatedListOptions = {
                    model: vm.institutionsList
                }});
        }
    }
})();


