(function () {
    'use strict';
    angular
        .module('app')
        .controller('DashboardUsersController', DashboardUsersController);

    DashboardUsersController.$inject = ['UsersListPaginated', 'User'];

    function DashboardUsersController(UsersListPaginated, User) {
        var vm = this;

        vm.paginatedListOptions = null;

        vm.dashboardListItemConfig = {
            title: 'email',
            state: 'profile',
            stateParamsMap: {id: 'id'},
            indicators: [
                {className: 'fa-list-ol', field: 'institutionsCount', title: 'Кількість Секцій'},
                {className: 'fa-calendar', field: 'createdAt', title: 'Дата Додавання', isUTCTimestamp: true}
            ]
        };

        activate();

        function activate() {
            vm.usersList = new UsersListPaginated({
                limit: 10,
                offset: 0
            });

            vm.usersList.getRemote()
                .then(function() {
                    vm.paginatedListOptions = {
                        model: vm.usersList
                    }});
        }
    }
})();


