(function () {
    'use strict';
    angular
        .module('app')
        .directive('videos', videos);

    videos.$inject = [];
    /* @ngInject */
    function videos() {
        var directive = {
            bindToController: true,
            controller: VideosController,
            controllerAs: 'vm',
            restrict: 'A',
            templateUrl: 'institutions-state/institution-state/directives/videos/videos.html',
            scope: {
                institution: '='
            }
        };

        return directive;
    }

    VideosController.$inject = ['$scope', '$rootScope', '$stateParams', 'users', 'MediaItemsList',
        'instService', 'modalService'];

    function VideosController($scope, $rootScope, $stateParams, users, MediaItemsList,
                                         instService,  modalService) {
        var vm = this;

        var instId = $stateParams.id;
        var listeners = [];

        vm.videos = new MediaItemsList({
            type: 'videos',
            institutionId: instId
        });

        vm.isOwnerOrAdmin = isOwnerOrAdmin;
        vm.showVideo = showVideo;
        vm.loadMore = loadMore;
        vm.showAddVideoModal = showAddVideoModal;
        vm.isMoreButtonVisible = isMoreButtonVisible;

        activate();

        function activate() {
            vm.inst = vm.institution;
            vm.videos.getRemote();

            listeners.push($rootScope.$on('video-deleted', function(event, payload) {
                removeVideoById(payload.id);
            }));

            $scope.$on('$destroy', function() {
                _.each(listeners, function(elem) {
                    elem();
                })
            });
        }

        function showVideo(video) {
            modalService.showVideoModal({ytbUrl: video.ytbUrl});
        }

        function isOwnerOrAdmin() {
            if (!vm.inst || !users.current) { return false; }
            var isOwner = parseInt(vm.inst.owner) === parseInt(users.current.id);
            var isAdmin = users.current.role === 'admin' || users.current.role === 'superman';

            return isAdmin || isOwner;
        }

        function removeVideoById(id) {
            var deletedVideo = _.find(vm.videos, function(elem) {
                return elem.id === id;
            });
            var index = _.indexOf(vm.videos, deletedVideo);
            vm.videos.data.splice(index, 1);
        }

        function loadMore() {
            if (vm.videos.isLoadInProcess || vm.videos.allMediaItemsLoaded) { return false; }

            vm.videos.getRemote();
        }

        function showAddVideoModal() {
            modalService.showManageVideoModal({
                instId: instId,
                title: 'Додати відео',
                instance: 'institution',
                onSuccess: onAddVideoSuccess
            })
        }

        function onAddVideoSuccess(videoData) {
            vm.videos.add(videoData, true);
        }

        function isMoreButtonVisible() {
            return !vm.videos.areAllItemsLoaded() && !vm.videos.isLoadInProcess;
        }
    }

})();

