(function () {
    'use strict';

    angular
        .module('app')
        .directive('logoNavigation', logoNavigation);

    logoNavigation.$inject = [];
    /* @ngInject */
    function logoNavigation() {
        var directive = {
            replace: true,
            bindToController: true,
            controller: LogoNavigationController,
            controllerAs: 'vm',
            restrict: 'A',
            templateUrl: 'layout/logo-navigation/logo-navigation.html',
            scope: {}
        };

        return directive;
    }

    LogoNavigationController.$inject = ['$state', 'routerHelper', 'users'];

    function LogoNavigationController($state, routerHelper, users) {
        var vm = this;

        vm.isStateActive = isStateActive;
        vm.isStateAvailable = isStateAvailable;
        vm.getUserId = getUserId;


        function isStateActive(name) {
            if (!$state.current || !$state.current.name) { return false; }
            return $state.current.name.includes(name);
        }

        function isStateAvailable(stateName) {
            return routerHelper.isStateAvailable(stateName);
        }

        function getUserId() {
            return users.current && users.current.id;
        }

    }
})();