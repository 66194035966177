(function(){
    'use strict';

    angular
        .module('app')
        .config(['$stateProvider', function($stateProvider) {
            $stateProvider
                .state('404', {
                    url:'/404',
                    templateUrl: '404-state/404.view.html',
                    controller: 'PageNotFoundController',
                    controllerAs: 'vm'
                })
        }])
})();