(function() {
    'use strict';

    angular
        .module('app')
        .factory('DataModelListPaginated', dataModelListPaginated);

    dataModelListPaginated.$inject = ['DataModelList'];
    /* @ngInject */
    function dataModelListPaginated(DataModelList) {
        _(DataModelListPaginated.prototype).extend(DataModelList.prototype);

        function DataModelListPaginated(options) {
            this.offset = options.offset || 0;
            this.limit = options.limit;
            this.total = null;
            this.pageNumber = options.pageNumber || 0;
            this.isLoading = false;
        }

        DataModelListPaginated.prototype.setPageNumber = function(number) {
            this.pageNumber = number;
            this.onPageNumberChanged();
        };

        DataModelListPaginated.prototype.previousPage = function() {
            if (this.pageNumber === 1) { return false; }
            this.pageNumber -= 1;
            this.onPageNumberChanged();
        };

        DataModelListPaginated.prototype.nextPage = function() {
            if (this.pageNumber === this.pagesCount) { return false }
            this.pageNumber += 1;
            this.onPageNumberChanged();
        };

        DataModelListPaginated.prototype.onPageNumberChanged = function() {
            this.offset = this.limit * (this.pageNumber - 1);
            this.getRemote();
        };

        DataModelListPaginated.prototype.onRemoteRequestSuccess = function(response) {
            this.isLoading = false;
            this.total = response.data.total;
            this.offset = response.data.offset;
            this.visiblePageNumbers = [];
            this.data.length = 0;

            this.setPagesCount();
            this.setCurrentPage();
            this.setFastAccessPagesList();

            this.addList(response.data.data);
        };

        DataModelListPaginated.prototype.setPagesCount = function() {
            this.pagesCount = parseInt(Math.ceil(this.total / this.limit)) || 1;
        };

        DataModelListPaginated.prototype.setCurrentPage = function() {
            this.pageNumber = parseInt(this.offset / this.limit) + 1;
        };

        DataModelListPaginated.prototype.setFastAccessPagesList = function() {
            this.visiblePageNumbers.length = 0;
            if (this.pagesCount < 10) {
                for (var i = 1; i <= this.pagesCount; i++) {
                    this.visiblePageNumbers.push(i);
                }
            } else {
                this.setFastAccessFirstPages();
                this.setFastAccessMiddlePages();
                this.setFastAccessLastPages();
                this.cleanUpFastAccessPagesList();
            }
        };

        DataModelListPaginated.prototype.setFastAccessFirstPages = function() {
            this.visiblePageNumbers.push(1, 2, 3);
        };

        DataModelListPaginated.prototype.setFastAccessLastPages = function() {
            this.visiblePageNumbers.push(this.pagesCount - 2, this.pagesCount - 1, this.pagesCount)
        };

        DataModelListPaginated.prototype.setFastAccessMiddlePages = function() {
            this.visiblePageNumbers.push(this.pageNumber - 2, this.pageNumber - 1, this.pageNumber,
                this.pageNumber + 1, this.pageNumber + 2);
        };

        DataModelListPaginated.prototype.cleanUpFastAccessPagesList = function() {
            this.visiblePageNumbers = _.uniq(this.visiblePageNumbers);
            this.visiblePageNumbers = _.filter(this.visiblePageNumbers, function(elem) {
                return elem > 0;
            });
        };

        return DataModelListPaginated;
    }
})();