(function() {
    'use strict';

    angular
        .module('app')
        .factory('InstitutionsListPaginated', institutionsListPaginated);

    institutionsListPaginated.$inject = ['DataModelListPaginated', 'instService', 'Institution'];
    /* @ngInject */
    function institutionsListPaginated(DataModelListPaginated, instService, Institution) {
        _(InstitutionsListPaginated.prototype).extend(DataModelListPaginated.prototype);

        function InstitutionsListPaginated(options) {
            this.model = options.model;
            this.offset = options.offset || 0;
            this.limit = options.limit || 10;
            this.pageNumber = options.pageNumber || 0;
            this.data = [];
            this.model = Institution;
            this.isLoading = false;
        }

        InstitutionsListPaginated.prototype.getRemote = function() {
            this.isLoading = true;

            return instService.getAll({
                offset: this.offset,
                limit: this.limit
            }).then(this.onRemoteRequestSuccess.bind(this));
        };



        return InstitutionsListPaginated;
    }
})();