(function () {
    'use strict';
    angular
        .module('app')
        .controller('DefaultStateController', DefaultStateController);

    DefaultStateController.$inject = [];

    function DefaultStateController() {
        var vm = this;



        activate();

        function activate() {

        }


    }

})();
