(function () {
    'use strict';

    angular
        .module('app')
        .controller('PrivacyController', PrivacyController);

    PrivacyController.$inject = [];

    function PrivacyController() {
        var vm = this;



    }

})();

